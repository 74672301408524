import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  fetchMoreSearchProducts,
  getAiBotResponse,
  store_uuid,
  getSuggestions,
} from "../../utils/utils";

import Footer from "../../components/Footer";
import WhiteButtonList from "../../components/WhiteButtonList";
import BudgetRangeSlider from "../../components/RangeSlider";
import ProductsList from "../../components/ProductsList";
import LogoutModal from "../../components/LogoutModal";
import Loader from "../../components/Loader";
import CodeScanner from "../../components/CodeScanner";
import Modal from "../../components/Modal";

import "./CategoryProduct.scss";

const CategoryProduct = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      props.complementaryProducts &&
      props.complementaryProducts.length &&
      !props.selectedCategory
    ) {
      navigate(`/?${store_uuid}`);
    } else if (!props.selectedInitCategory) {
      navigate(`/?${store_uuid}`);
    }
  }, []);

  useEffect(() => {
    if (
      props.complementaryProducts &&
      props.complementaryProducts.length === 0
    ) {
      dispatch({
        type: "SET_INIT_SELECTED_CATEGORY",
        selectedInitCategory: props.productWindow[0].title,
      });
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        selectedProducts: props.productWindow[0].products,
      });
    }
  }, []);

  const handleOnScrollProductContainer = async (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      const currProduct = props.productWindow.filter((item) => {
        if (item.title === props.selectedCategory) {
          return item;
        }
      })[0];

      const pageParams = currProduct && currProduct.paginations_params;
      if (
        pageParams &&
        pageParams.offset < pageParams.num_found &&
        !props.isLoading
      ) {
        dispatch({ type: "SET_IS_LOADING", isLoading: true });
        const response = await fetchMoreSearchProducts(pageParams);

        dispatch({
          type: "SET_PRODUCT_WINDOW",
          productWindow: props.productWindow.map((item) => {
            if (item.title === props.selectedCategory) {
              item["paginations_params"] =
                response.product_window &&
                response.product_window[0] &&
                response.product_window[0].paginations_params;
              return item;
            }
            return item;
          }),
        });
        dispatch({
          type: "SET_SELECTED_PRODUCTS",
          selectedProducts: [
            ...props.selectedProducts,
            ...response.product_window[0].products,
          ],
        });
        dispatch({ type: "SET_IS_LOADING", isLoading: false });
      } else {
        console.log("no products");
      }
    }
  };

  const getSuggestionData = async (messageId) => {
    try {
      const response = await getSuggestions(messageId);
      if (response.ok) {
        const suggestionsObject = await response.json();
        dispatch({
          type: "SET_SUGGESTED_QUESTION",
          suggestedQuestionData: suggestionsObject,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBotResponse = async () => {
    let productId;
    const isProductWindow = window.location.pathname.includes("product");
    if (isProductWindow) {
      productId = window.location.pathname.split("/")[2];
    } else {
      navigate(`/?${store_uuid}`);
    }
    dispatch({ type: "SET_IS_LOADING", isLoading: true });
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
    dispatch({
      type: "SET_SUGGESTED_QUESTION",
      suggestdQuestion: [],
    });
    dispatch({
      type: "SET_SELECTED_PRODUCTS",
      selectedProducts: [],
    });
    dispatch({
      type: "SET_PRODUCT_WINDOW",
      productWindow: [],
    });
    dispatch({
      type: "SET_BOT_MESSAGE",
      botMessage: "",
    });
    dispatch({
      type: "SET_PRODUCT_SPECIFIC_ANS",
      productSpecficAns: "",
    });

    dispatch({
      type: "SET_INIT_SELECTED_CATEGORY",
      selectedInitCategory: "",
    });

    dispatch({
      type: "SET_COMPLEMENTARY_PRODUCTS",
      complementaryProducts: [],
    });

    const messageId = String(Date.now());
    const body = {
      message: props.userInput,
      user_id: localStorage.getItem("stylebot_user_id"),
      session_id: localStorage.getItem("stylebot_session_id"),
      message_id: messageId,
      price_range: [props.priceFilter[0], props.priceFilter[1]],
      openai_model: "gpt-3.5-turbo",
      openai_temperature: "0.33",
    };

    if (productId) {
      body["product_id"] = productId;
    }

    dispatch({
      type: "SET_USER_MESSAGE",
      userMessage: props.userInput,
    });
    dispatch({ type: "SET_USER_INPUT", userInput: "" });
    try {
      const response = await getAiBotResponse(body);
      if (response.ok) {
        const reader = response.body.getReader();
        let receivedData = "";
        while (true) {
          const { done, value } = await reader.read();
          const chunk = new TextDecoder().decode(value);

          receivedData += chunk;
          if (done) {
            console.log("done");
            const textEndIdx = receivedData.indexOf("[TXT_END]");
            if (!isProductWindow) {
              const productEndIdx = receivedData.lastIndexOf("[PRODUCT_END]");
              const additionalData = JSON.parse(
                receivedData.substring(textEndIdx + 9, productEndIdx)
              );
              const tempProductwindow = additionalData?.product_window;
              dispatch({
                type: "SET_PRODUCT_WINDOW",
                productWindow: tempProductwindow,
              });
            }
            dispatch({ type: "SET_IS_LOADING", isLoading: false });

            break;
          } else {
            const textEnd = chunk.split(" ").includes("[TXT_END]");
            const productEnd = chunk.split(" ").includes("[PRODUCT_END]");
            if (textEnd) {
              if (!isProductWindow) {
                getSuggestionData(messageId);
              }
              const lastIndexOfTxt = receivedData.lastIndexOf("[TXT_END]");
              const data = receivedData.substring(0, lastIndexOfTxt);
              if (window.location.pathname.includes("product")) {
                dispatch({
                  type: "SET_PRODUCT_SPECIFIC_ANS",
                  productSpecficAns: data,
                });
              } else {
                dispatch({ type: "SET_BOT_MESSAGE", botMessage: data });
              }
            } else if (
              !receivedData.includes("[TXT_END]") &&
              !productEnd &&
              !textEnd
            ) {
              if (window.location.pathname.includes("product")) {
                dispatch({
                  type: "SET_PRODUCT_SPECIFIC_ANS",
                  productSpecficAns: receivedData,
                });
              } else {
                dispatch({ type: "SET_BOT_MESSAGE", botMessage: receivedData });
              }
            }
          }
        }
      } else {
        dispatch({ type: "SET_IS_LOADING", isLoading: false });
        console.log("Somthing went wrong");
      }
    } catch (err) {
      dispatch({ type: "SET_IS_LOADING", isLoading: false });
      console.log(err);
    }
  };

  const handleOnClickSaveAndProceed = () => {
    dispatch({ type: "SET_SHOW_SUGG_OPTIONS", showSuggOptions: false });
    getBotResponse(false);
  };

  const handleOnSelectFollowUp = (ans) => {
    if (props.userInput && props.userInput.includes(ans)) {
      const temp = props.userInput.split(",").filter((item) => item !== ans);
      dispatch({ type: "SET_USER_INPUT", userInput: temp.join(",") });
      dispatch({
        type: "SET_USER_MESSAGE",
        userMessage: temp.join(","),
      });
    } else {
      let temp = props.userInput ? props.userInput : false;
      if (temp) {
        dispatch({
          type: "SET_USER_INPUT",
          userInput: temp + "," + ans,
        });
      } else {
        dispatch({
          type: "SET_USER_INPUT",
          userInput: ans,
        });
      }
      temp = props.userMessage ? props.userMessage : false;
      if (temp) {
        dispatch({
          type: "SET_USER_MESSAGE",
          userMessage: temp + "," + ans,
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          userMessage: ans,
        });
      }
    }
  };

  const handleOnClickCategory = (category) => {
    let currCategory = props.productWindow.filter(
      (cat) => cat.title === category
    )[0];

    if (props.complementaryProducts && props.complementaryProducts.length > 0) {
      currCategory = props.complementaryProducts.filter(
        (cat) => cat.title === category
      )[0];

      dispatch({
        type: "SET_SELECTED_CATEGORY",
        selectedCategory: category,
      });
    } else {
      dispatch({
        type: "SET_INIT_SELECTED_CATEGORY",
        selectedInitCategory: category,
      });
    }
    if (currCategory && currCategory.products) {
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        selectedProducts: currCategory.products,
      });
    }
  };

  const handleOnClickProduct = (product) => {
    dispatch({ type: "SET_SELECTED_PRODUCT", selectedProduct: product });
    navigate(`/product/${product.product_code}?store_uuid=${store_uuid}`);
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
  };

  return (
    <>
      {props.showScanner ? (
        <>
          <CodeScanner />
        </>
      ) : (
        <div className="category-message-contianer">
          <div className="title-header">{props.userMessage}</div>
          {props.isLoading && !props.selectedCategory ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="products-list-category-home">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div className="budget">Budget</div>
                  <BudgetRangeSlider
                    value={props.priceFilter}
                    onChange={(val) =>
                      dispatch({
                        type: "SET_PRICE_FILTER",
                        priceFilter: val,
                      })
                    }
                  />
                  <div className="price-range-container">
                    <div className="price-range">{props.priceFilter[0]}</div>
                    <div className="price-range">{props.priceFilter[1]}</div>
                  </div>
                </div>
              </div>
              <WhiteButtonList
                style={{ marginBottom: "24px", padding: "0px" }}
                activeItem={
                  props.complementaryProducts &&
                  props.complementaryProducts.length > 0
                    ? props.selectedCategory
                    : props.selectedInitCategory
                }
                onClick={handleOnClickCategory}
                btnList={
                  props.complementaryProducts &&
                  props.complementaryProducts.length > 0
                    ? props.complementaryProducts.map((item) => item.title)
                    : props.productWindow.map((item) => item.title)
                }
              />
              <div className="products-list-home">
                <ProductsList
                  onClickProduct={handleOnClickProduct}
                  products={props.selectedProducts}
                  handleOnScrollProductContainer={
                    handleOnScrollProductContainer
                  }
                />
              </div>
            </div>
          )}
          <Footer />
          {props.showLogOutModal && <LogoutModal />}
          {props.showSuggOptions && (
            <Modal
              className="modal-visible"
              slideUp={props.showSuggOptions}
              onClose={() =>
                dispatch({
                  type: "SET_SHOW_SUGG_OPTIONS",
                  showSuggOptions: false,
                })
              }
            >
              <div className="sugg-modal-container">
                <div className="modal-main-header">
                  {props.suggestedQuestionData &&
                    props.suggestedQuestionData.followup_question}
                </div>
                <div className="sugg-options-container">
                  {props.suggestedQuestionData &&
                    props.suggestedQuestionData.followup_options &&
                    props.suggestedQuestionData.followup_options.length &&
                    props.suggestedQuestionData.followup_options.map(
                      (item, idx) => {
                        return (
                          <div className="sugg-option" key={idx}>
                            <input
                              type="checkbox"
                              onChange={() => handleOnSelectFollowUp(item)}
                            />
                            <div>{item}</div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div
                  className="save-proceed"
                  onClick={handleOnClickSaveAndProceed}
                >
                  Save and Proceed
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  productWindow: state.productWindow.productWindow,
  selectedCategory: state.productWindow.selectedCategory,
  selectedProducts: state.productWindow.selectedProducts,
  selectedProduct: state.productWindow.selectedProduct,
  recommandedProducts: state.productWindow.recommandedProducts,
  listening: state.chatWindow.listening,
  userInput: state.chatWindow.userInput,
  suggestdQuestion: state.chatWindow.suggestdQuestion,
  showInput: state.chatWindow.showInput,
  botMessage: state.chatWindow.botMessage,
  showLogOutModal: state.chatWindow.showLogOutModal,
  isLoading: state.chatWindow.isLoading,
  userMessage: state.chatWindow.userMessage,
  complementaryProducts: state.productWindow.complementaryProducts,
  selectedInitCategory: state.productWindow.selectedInitCategory,
  showScanner: state.chatWindow.showScanner,
  priceFilter: state.chatWindow.priceFilter,
  suggestedQuestionData: state.chatWindow.suggestedQuestionData,
  showSuggOptions: state.chatWindow.showSuggOptions,
});

export default connect(mapStateToProps)(CategoryProduct);
