import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import "./style.scss";

const BudgetRangeSlider = (props) => {
  return (
    <div>
      <RangeSlider
        className="range-class"
        min={props.min ? props.min : 25}
        max={props.max ? props.max : 36000}
        value={props.value}
        onInput={props.onChange}
      />
    </div>
  );
};

export default BudgetRangeSlider;
