import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./containers/Home/Home.jsx";
import ProductsWindow from "./containers/ProductsWindow/ProductsWindow.jsx";
import NavBar from "./components/Navbar/index.jsx";
import RecommandetionWindow from "./containers/RecommandetionWindow/RecommandetionWindow.jsx";
import CategoryProduct from "./containers/CategoryProduct/CategoryProduct.jsx";

import "./App.scss";


// To add authentication wrap the element in ProtectedRoute eg: <ProtectedRoute><Home/><ProtectedRoute/>

const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("stylebot_token");
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  return (
    <>
      <NavBar />
      <Routes>
        {/* <Route path="login" element={<LoginPage />} /> */}
        <Route path="/" exact element={<Home />} />
        <Route path="category/:category_id" element={<CategoryProduct />} />
        <Route path="product/:product_id" element={<ProductsWindow />} />
        <Route path="recommandation" element={<RecommandetionWindow />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
};

export default App;
