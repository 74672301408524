import React from "react";
import Modal from "../Modal";

import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import closeIcon from "../../images/close.svg";
import userIcon from "../../images/user.svg";
import resetIcon from "../../images/reset-icon.svg";
// import logOut from "../../images/log-out.svg";

import "./style.scss";

const LogoutModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = localStorage.getItem("stylebot_email");

  // const handleOnClickLogout = () => {
  //   localStorage.removeItem("stylebot_email");
  //   localStorage.removeItem("stylebot_token");

  //   navigate("/login");
  //   dispatch({
  //     type: "SET_SHOW_HIDE_INPUT",
  //     showInput: false,
  //   });
  //   dispatch({ type: "SET_IS_LOADING", isLoading: false });
  //   dispatch({
  //     type: "SET_SUGGESTED_QUESTION",
  //     suggestdQuestion: [],
  //   });
  //   dispatch({
  //     type: "SET_SELECTED_PRODUCTS",
  //     selectedProducts: [],
  //   });
  //   dispatch({
  //     type: "SET_PRODUCT_WINDOW",
  //     productWindow: [],
  //   });
  //   dispatch({
  //     type: "SET_BOT_MESSAGE",
  //     botMessage: "",
  //   });
  //   dispatch({
  //     type: "SET_SHOW_LOGOUT_MODAL",
  //     showLogOutModal: false,
  //   });
  //   dispatch({ type: "SET_USER_INPUT", userInput: "" });
  // };

  const handleOnClickReset = () => {
    navigate("/");
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
    dispatch({ type: "SET_IS_LOADING", isLoading: false });
    dispatch({
      type: "SET_SUGGESTED_QUESTION",
      suggestdQuestion: [],
    });
    dispatch({
      type: "SET_SELECTED_PRODUCTS",
      selectedProducts: [],
    });
    dispatch({
      type: "SET_PRODUCT_WINDOW",
      productWindow: [],
    });
    dispatch({
      type: "SET_BOT_MESSAGE",
      botMessage: "",
    });
    dispatch({
      type: "SET_SHOW_LOGOUT_MODAL",
      showLogOutModal: false,
    });
    dispatch({ type: "SET_USER_INPUT", userInput: "" });
    dispatch({
      type: "SET_RECOMMANDED_PRODUCTS",
      recommandedProducts: [],
    });
  };

  return (
    <Modal
      className={props.slideUp ? "modal-visible-plus-50" : ""}
      slideUp={props.slideUp}
      onClose={() =>
        dispatch({
          type: "SET_SHOW_LOGOUT_MODAL",
          showLogOutModal: false,
        })
      }
    >
      <div className="logout-main">
        <div className="log-out-container">
          <div className="titles">
            <div>
              <img src={userIcon} alt="user-icon" />
            </div>
            <div>{email}</div>
          </div>
          <div className="titles" onClick={handleOnClickReset}>
            <div>
              <img src={resetIcon} alt="user-icon" />
            </div>
            <div>Reset Conversation</div>
          </div>
          {/* <div className="titles" onClick={handleOnClickLogout}>
            <div>
              <img src={logOut} alt="user-icon" />
            </div>
            <div>Log Out</div>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showLogOutModal: state.chatWindow.showLogOutModal,
});

export default connect(mapStateToProps)(LogoutModal);
