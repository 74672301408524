import React, { useState, useEffect } from "react";

import greenStars from "../../images/stars-icon-green.svg";
import maleIcon from "../../images/male-icon.svg";
import femaleIcon from "../../images/female-icon.svg";
import maleLargeIcon from "../../images/male-large.svg";
import femaleLargeIcon from "../../images/female-large.svg";
import boyLargeIcon from "../../images/boy-large.svg";
import girlLargeIcon from "../../images/girl-large.svg";

import graphIcon from "../../images/filter-graph.svg";
import arrowSmall from "../../images/arrow-small.svg";

import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { baseUrl } from "../../utils/constents";
import {
  store_uuid,
  getSuggestions,
  getAiBotResponse,
} from "../../utils/utils";

import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import CardStack from "../../components/CardStack";
import LogoutModal from "../../components/LogoutModal";
import Modal from "../../components/Modal";
import BudgetRangeSlider from "../../components/RangeSlider";
import CodeScanner from "../../components/CodeScanner";

import "./home.scss";

const Home = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPriceGraph, setShowHidePriceGraph] = useState(false);
  const [showGenderModal, setShowGenderModal] = useState(false);

  useEffect(() => {
    getStoreConfig();
  }, []);

  const getStoreConfig = async () => {
    try {
      const response = await fetch(`${baseUrl}/store/${store_uuid}/ping`);
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: "SET_CONFIG_DATA",
          configData: responseData,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnClickCategory = (category) => {
    const currCategory = props.productWindow.filter(
      (cat) => cat.title === category
    )[0];

    if (currCategory && currCategory.products) {
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        selectedProducts: currCategory.products,
      });
      dispatch({
        type: "SET_INIT_SELECTED_CATEGORY",
        selectedInitCategory: category,
      });
      navigate(
        `/category/${currCategory.paginations_params.category}?store_uuid=${store_uuid}`
      );
      dispatch({
        type: "SET_SHOW_HIDE_INPUT",
        showInput: false,
      });
    }
  };

  const createUserProfile = async () => {
    const gender =
      props.selectedGender === "male"
        ? "men"
        : props.selectedGender === "female"
        ? "women"
        : props.selectedGender;

    const user_id = localStorage.getItem("stylebot_user_id");
    const session_id = localStorage.getItem("stylebot_session_id");

    const url = `${baseUrl}/store/${store_uuid}/user?gender=${gender}&store_uuid=${store_uuid}&user_id=${user_id}&session_id=${session_id}`;

    try {
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const getSuggestionData = async (messageId) => {
    try {
      const response = await getSuggestions(messageId);
      if (response.ok) {
        const suggestionsObject = await response.json();
        dispatch({
          type: "SET_SUGGESTED_QUESTION",
          suggestedQuestionData: suggestionsObject,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBotResponse = async (message) => {
    if (message) {
      createUserProfile();
    }

    const gender =
      props.selectedGender === "male"
        ? "men"
        : props.selectedGender === "female"
        ? "women"
        : props.selectedGender;
    navigate(`?store_uuid=${store_uuid}`);
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
    dispatch({ type: "SET_IS_LOADING", isLoading: true });
    dispatch({
      type: "SET_SUGGESTED_QUESTION",
      suggestdQuestion: null,
    });
    dispatch({
      type: "SET_SELECTED_PRODUCTS",
      selectedProducts: [],
    });
    dispatch({
      type: "SET_PRODUCT_WINDOW",
      productWindow: [],
    });
    dispatch({
      type: "SET_BOT_MESSAGE",
      botMessage: "",
    });

    const messageId = String(Date.now());

    const body = {
      message: message ? `I am ${gender},${message}` : props.userMessage,
      user_id: localStorage.getItem("stylebot_user_id"),
      session_id: localStorage.getItem("stylebot_session_id"),
      message_id: messageId,
      price_range: [props.priceFilter[0], props.priceFilter[1]],
      openai_model: "gpt-3.5-turbo",
      openai_temperature: "0.33",
    };

    dispatch({ type: "SET_USER_INPUT", userInput: "" });

    try {
      const response = await getAiBotResponse(body);
      if (response.ok) {
        const reader = response.body.getReader();
        let receivedData = "";
        while (true) {
          const { done, value } = await reader.read();
          const chunk = new TextDecoder().decode(value);

          receivedData += chunk;
          if (done) {
            const textEndIdx = receivedData.indexOf("[TXT_END]");
            const productEndIdx = receivedData.lastIndexOf("[PRODUCT_END]");
            const additionalData = JSON.parse(
              receivedData.substring(textEndIdx + 9, productEndIdx)
            );
            const tempProductwindow = additionalData?.product_window;
            dispatch({
              type: "SET_PRODUCT_WINDOW",
              productWindow: tempProductwindow,
            });
            dispatch({ type: "SET_IS_LOADING", isLoading: false });

            break;
          } else {
            const textEnd = chunk.split(" ").includes("[TXT_END]");
            const productEnd = chunk.split(" ").includes("[PRODUCT_END]");

            if (textEnd) {
              getSuggestionData(messageId);
              const lastIndexOfTxt = receivedData.lastIndexOf("[TXT_END]");
              const data = receivedData.substring(0, lastIndexOfTxt);
              dispatch({ type: "SET_BOT_MESSAGE", botMessage: data });
            } else if (
              !receivedData.includes("[TXT_END]") &&
              !productEnd &&
              !textEnd
            ) {
              dispatch({ type: "SET_BOT_MESSAGE", botMessage: receivedData });
            }
          }
        }
      } else {
        dispatch({ type: "SET_IS_LOADING", isLoading: false });
        console.log("Somthing went wrong");
      }
    } catch (err) {
      dispatch({ type: "SET_IS_LOADING", isLoading: false });
      console.log(err);
    }
  };

  const handleOnClickApply = () => {
    setShowHidePriceGraph(false);
  };

  const handleOnClickReset = () => {
    dispatch({
      type: "SET_PRICE_FILTER",
      priceFilter: [25, 36000],
    });
  };

  const handleOnClickStaticMessage = (e) => {
    dispatch({
      type: "SET_USER_INPUT",
      userInput: e.target.textContent.slice(1, -1),
    });
    dispatch({
      type: "SET_USER_MESSAGE",
      userMessage: e.target.textContent.slice(1, -1),
    });
    dispatch({ type: "SET_SHOW_HIDE_INPUT", showInput: true });
    getBotResponse(e.target.textContent.slice(1, -1));
  };

  const handleOnClickSaveAndProceed = () => {
    dispatch({ type: "SET_SHOW_SUGG_OPTIONS", showSuggOptions: false });
    getBotResponse(false);
  };

  const handleOnSelectFollowUp = (ans) => {
    if (props.userInput && props.userInput.includes(ans)) {
      const temp = props.userInput.split(",").filter((item) => item !== ans);
      dispatch({ type: "SET_USER_INPUT", userInput: temp.join(",") });
      dispatch({
        type: "SET_USER_MESSAGE",
        userMessage: temp.join(","),
      });
    } else {
      let temp = props.userInput ? props.userInput : false;
      if (temp) {
        dispatch({
          type: "SET_USER_INPUT",
          userInput: temp + "," + ans,
        });
      } else {
        dispatch({
          type: "SET_USER_INPUT",
          userInput: ans,
        });
      }
      temp = props.userMessage ? props.userMessage : false;
      if (temp) {
        dispatch({
          type: "SET_USER_MESSAGE",
          userMessage: temp + "," + ans,
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          userMessage: ans,
        });
      }
    }
  };

  const handleOnSelectGender = (gender) => {
    switch (gender) {
      case "male":
        dispatch({
          type: "SET_SELECTED_GENDER",
          selectedGender: "male",
        });
        break;
      case "female":
        dispatch({
          type: "SET_SELECTED_GENDER",
          selectedGender: "female",
        });
        break;
      case "boy":
        dispatch({
          type: "SET_SELECTED_GENDER",
          selectedGender: "boy",
        });
        break;
      case "girl":
        dispatch({
          type: "SET_SELECTED_GENDER",
          selectedGender: "girl",
        });
        break;
      default:
        dispatch({
          type: "SET_SELECTED_GENDER",
          selectedGender: "male",
        });
    }
  };
  

  return (
    <>
      {props.showScanner ? (
        <>
          <CodeScanner />
        </>
      ) : (
        <div className="home-contianer">
          {props.botMessage &&
            props.botMessage.length > 0 &&
            props.botMessage !== undefined && (
              <div className="title-header">{props.userMessage}</div>
            )}
          {props.botMessage &&
            props.botMessage.length > 0 &&
            props.botMessage !== undefined && (
              <div className="bot-message">{props.botMessage}</div>
            )}
          <>
            {props.isLoading ? (
              <div className="loader-component">
                {/* <Loader /> */}
                {[...Array(1)].map((_, idx) => {
                  return (
                    <div
                      key={idx}
                      className="loader-div"
                      style={
                        props.botMessage && props.botMessage.length
                          ? { display: "none" }
                          : {}
                      }
                    ></div>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {[...Array(4)].map((_, idx) => {
                    return <div key={idx} className="card"></div>;
                  })}
                </div>
              </div>
            ) : (
              <div className="message-products">
                {!props.botMessage ? (
                  <div className="static-content">
                    <div className="main-header">
                      {props.configData && props.configData.initial_message
                        ? props.configData.initial_message
                        : "Your Personal Fashion Assistant, Always Trend-Ready."}
                      <img src={greenStars} alt="star" />
                    </div>
                    <div className="static-messages-container">
                      <div
                        className="static-message"
                        onClick={handleOnClickStaticMessage}
                      >
                        "What's the best outfit for my date tonight?"
                      </div>
                      <div
                        className="static-message"
                        onClick={handleOnClickStaticMessage}
                      >
                        "How can I style this dress for a party?"
                      </div>
                      <div
                        className="static-message"
                        onClick={handleOnClickStaticMessage}
                      >
                        "Help me choose the perfect shoes for work."
                      </div>
                    </div>
                    <div
                      className="gender-toggle-container"
                      onClick={() => setShowGenderModal(true)}
                    >
                      <div className="coose-gender">Choose Gender</div>
                      <div className="gender-drop-down">
                        <div>
                          {props.selectedGender === "male" ? (
                            <img src={maleIcon} alt="male" />
                          ) : props.selectedGender === "female" ? (
                            <img src={femaleIcon} alt="female" />
                          ) : props.selectedGender === "boy" ? (
                            <img src={boyLargeIcon} alt="boy" />
                          ) : props.selectedGender === "girl" ? (
                            <img src={girlLargeIcon} alt="girl" />
                          ) : (
                            <img src={maleIcon} alt="male" />
                          )}
                        </div>
                        <div>{props.selectedGender}</div>
                        <div>
                          <img src={arrowSmall} alt="arrow" />
                        </div>
                      </div>
                    </div>
                    {showGenderModal && (
                      <Modal
                        className="modal-visible"
                        slideUp={showGenderModal}
                        onClose={() => setShowGenderModal(false)}
                      >
                        <div className="gender-modal">
                          <div className="gender-modal-header">Gender</div>
                          <div className="gender-modal-sub">
                            <div className="gender-sub">
                              <div
                                onClick={() => handleOnSelectGender("male")}
                                className="gender"
                              >
                                <img
                                  src={maleLargeIcon}
                                  alt="male"
                                  className={
                                    props.selectedGender === "male"
                                      ? "selected-gender-img"
                                      : ""
                                  }
                                />
                                <div>Male</div>
                              </div>
                              <div
                                onClick={() => handleOnSelectGender("female")}
                                className="gender"
                              >
                                <img
                                  src={femaleLargeIcon}
                                  alt="female"
                                  className={
                                    props.selectedGender === "female"
                                      ? "selected-gender-img"
                                      : ""
                                  }
                                />
                                <div>Female</div>
                              </div>
                            </div>
                            <div className="gender-sub">
                              <div
                                onClick={() => handleOnSelectGender("boy")}
                                className="gender"
                              >
                                <img
                                  src={boyLargeIcon}
                                  alt="boy"
                                  className={
                                    props.selectedGender === "boy"
                                      ? "selected-gender-img"
                                      : ""
                                  }
                                />
                                <div>Boy</div>
                              </div>
                              <div
                                onClick={() => handleOnSelectGender("girl")}
                                className="gender"
                              >
                                <img
                                  src={girlLargeIcon}
                                  alt="girl"
                                  className={
                                    props.selectedGender === "girl"
                                      ? "selected-gender-img"
                                      : ""
                                  }
                                />
                                <div>Girl</div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="next-btn"
                            onClick={() => setShowGenderModal(false)}
                          >
                            Next
                          </div>
                        </div>
                      </Modal>
                    )}
                    <div
                      onClick={() => setShowHidePriceGraph(true)}
                      className="price-filter-container"
                    >
                      <div>Price</div>
                      <div>{props.priceFilter && props.priceFilter[0]}</div>
                      <img src={graphIcon} alt="graph" />
                      <div>{props.priceFilter && props.priceFilter[1]}</div>
                      <div>
                        <img src={arrowSmall} alt="arrow" />
                      </div>
                    </div>
                    {showPriceGraph && (
                      <Modal
                        className="modal-visible"
                        slideUp={showPriceGraph}
                        onClose={() => setShowHidePriceGraph(false)}
                        leftHeader="Price"
                      >
                        <div className="modal-content">
                          {props.isLoading ? (
                            <>
                              <Loader />
                            </>
                          ) : (
                            <>
                              <BudgetRangeSlider
                                min={25}
                                max={36000}
                                value={props.priceFilter}
                                onChange={(val) =>
                                  dispatch({
                                    type: "SET_PRICE_FILTER",
                                    priceFilter: val,
                                  })
                                }
                              />
                              <div className="min-max-container">
                                <div className="white-btn">
                                  {props.priceFilter[0]}
                                </div>
                                <div>-</div>
                                <div className="white-btn">
                                  {props.priceFilter[1]}
                                </div>
                              </div>
                              <div className="min-max-container">
                                <div
                                  style={{ textAlign: "center" }}
                                  className="white-btn"
                                  onClick={handleOnClickReset}
                                >
                                  Reset
                                </div>
                                <div
                                  style={{ textAlign: "center" }}
                                  className="black-btn-apply"
                                  onClick={handleOnClickApply}
                                >
                                  Apply
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </Modal>
                    )}
                  </div>
                ) : (
                  <>
                    <CardStack
                      productWindow={props.productWindow}
                      handleOnClickCategory={(title) =>
                        handleOnClickCategory(title)
                      }
                    />
                  </>
                )}
              </div>
            )}
          </>
          {props.showSuggOptions && (
            <Modal
              className="modal-visible"
              slideUp={props.showSuggOptions}
              onClose={() =>
                dispatch({
                  type: "SET_SHOW_SUGG_OPTIONS",
                  showSuggOptions: false,
                })
              }
            >
              <div className="sugg-modal-container">
                <div className="modal-main-header">
                  {props.suggestedQuestionData &&
                    props.suggestedQuestionData.question_keyword}
                </div>
                <div className="sugg-options-container">
                  {props.suggestedQuestionData &&
                    props.suggestedQuestionData.probable_answers &&
                    props.suggestedQuestionData.probable_answers.length &&
                    props.suggestedQuestionData.probable_answers.map(
                      (item, idx) => {
                        return (
                          <div className="sugg-option" key={idx}>
                            <input
                              type="checkbox"
                              onChange={() => handleOnSelectFollowUp(item)}
                            />
                            <div>{item}</div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div
                  className="save-proceed"
                  onClick={handleOnClickSaveAndProceed}
                >
                  Save and Proceed
                </div>
              </div>
            </Modal>
          )}
          <Footer />
          {props.showLogOutModal && (
            <LogoutModal slideUp={props.showLogOutModal} />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  productWindow: state.productWindow.productWindow,
  selectedCategory: state.productWindow.selectedCategory,
  selectedProducts: state.productWindow.selectedProducts,
  selectedProduct: state.productWindow.selectedProduct,
  recommandedProducts: state.productWindow.recommandedProducts,
  botMessage: state.chatWindow.botMessage,
  isLoading: state.chatWindow.isLoading,
  showInput: state.chatWindow.showInput,
  showLogOutModal: state.chatWindow.showLogOutModal,
  selectedGender: state.chatWindow.selectedGender,
  userMessage: state.chatWindow.userMessage,
  showScanner: state.chatWindow.showScanner,
  showSuggOptions: state.chatWindow.showSuggOptions,
  complementaryProducts: state.productWindow.complementaryProducts,
  priceFilter: state.chatWindow.priceFilter,
  suggestedQuestionData: state.chatWindow.suggestedQuestionData,
  userInput: state.chatWindow.userInput,
  configData: state.chatWindow.configData,
});

export default connect(mapStateToProps)(Home);
