import React, { useState } from "react";
import needleImg from "../../images/needle.svg";

import "./style.scss";

const CardStack = (props) => {
  return (
    <div className="categories-products">
      {props.productWindow &&
        props.productWindow.length > 0 &&
        props.productWindow.map((category, idx) => {
          return (
            <div
              key={idx}
              className="category"
              onClick={() => props.handleOnClickCategory(category.title)}
            >
              <div className="card-stack-1"></div>
              <div className="card-stack-2"></div>
              <img
                className="category-img"
                src={
                  category.products &&
                  category.products[0] &&
                  category.products[0].image_url
                    ? category.products[0].image_url[0]
                    : ""
                }
                alt="product-category"
                onError={(e) => (e.target.src = needleImg)}
              />
              <div className="category-title">{category.title}</div>
            </div>
          );
        })}
    </div>
  );
};

export default CardStack;
