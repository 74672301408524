import { actionNames } from "../constants";

const initialState = {
  productWindow: [],
  currentProducts: [],
  selectedCategory: "",
  selectedProducts: [],
  selectedProduct: null,
  recommandedProducts: [],
  recommandationType: "",
  selectedInitCategory: "",
  isLoadingRecom: false,
};

const productWindow = (state = initialState, action) => {
  switch (action.type) {
    case actionNames.SET_PRODUCT_WINDOW:
      return {
        ...state,
        productWindow: [...action.productWindow],
      };
    case actionNames.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case actionNames.SET_INIT_SELECTED_CATEGORY:
      return {
        ...state,
        selectedInitCategory: action.selectedInitCategory,
      };
    case actionNames.SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: [...action.selectedProducts],
      };
    case actionNames.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: { ...action.selectedProduct },
      };
    case actionNames.SET_RECOMMANDED_PRODUCTS:
      return {
        ...state,
        recommandedProducts: [...action.recommandedProducts],
      };
    case actionNames.SET_SIMILAR_PRODUCTS:
      return {
        ...state,
        similarProducts: [...action.similarProducts],
      };
    case actionNames.SET_COMPLEMENTARY_PRODUCTS:
      return {
        ...state,
        complementaryProducts: [...action.complementaryProducts],
      };
    case actionNames.SET_RECOMMANDATION_TYPE:
      return {
        ...state,
        recommandationType: action.recommandationType,
      };
    case actionNames.SET_ISLOADING_RECOM:
      return {
        ...state,
        isLoadingRecom: action.isLoadingRecom,
      };
    default:
      return {
        ...state,
      };
  }
};

export default productWindow;
