export const actionNames = {
  SET_PRODUCT_WINDOW: "SET_PRODUCT_WINDOW",
  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_SELECTED_PRODUCTS: "SET_SELECTED_PRODUCTS",
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
  SET_RECOMMANDED_PRODUCTS: "SET_RECOMMANDED_PRODUCTS",
  SET_SHOW_HIDE_INPUT: "SET_SHOW_HIDE_INPUT",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_SUGGESTED_QUESTION: "SET_SUGGESTED_QUESTION",
  SET_IS_LISTENING: "SET_IS_LISTENING",
  SET_USER_INPUT: "SET_USER_INPUT",
  SET_BOT_MESSAGE: "SET_BOT_MESSAGE",
  SET_RECOMMANDATION_TYPE: "SET_RECOMMANDATION_TYPE",
  SET_SHOW_LOGOUT_MODAL: "SET_SHOW_LOGOUT_MODAL",
  SET_SELECTED_GENDER: "SET_SELECTED_GENDER",
  SET_SHOW_LOGIN_MODAL: "SET_SHOW_LOGIN_MODAL",
  SET_USER_MESSAGE: "SET_USER_MESSAGE",
  SET_SHOW_SCANNER: "SET_SHOW_SCANNER",
  SET_SELECTED_QUESTION: "SET_SELECTED_QUESTION",
  SET_SHOW_SUGG_OPTIONS: "SET_SHOW_SUGG_OPTIONS",
  SET_COMPLEMENTARY_PRODUCTS: "SET_COMPLEMENTARY_PRODUCTS",
  SET_SIMILAR_PRODUCTS: "SET_SIMILAR_PRODUCTS",
  SET_INIT_SELECTED_CATEGORY: "SET_INIT_SELECTED_CATEGORY",
  SET_BARCODE_DATA: "SET_BARCODE_DATA",
  SET_PRICE_FILTER: "SET_PRICE_FILTER",
  SET_PRODUCT_SPECIFIC_ANS: "SET_PRODUCT_SPECIFIC_ANS",
  SET_ISLOADING_RECOM: "SET_ISLOADING_RECOM",
  SET_CONFIG_DATA: "SET_CONFIG_DATA",
};
