import { actionNames } from "../constants";

const initialState = {
  userInput: "",
  listening: false,
  showInput: false,
  isLoading: false,
  suggestdQuestion: [],
  setShowHideInput: false,
  botMessage: "",
  userMessage: "",
  showLogOutModal: false,
  selectedGender: "male",
  showLoginModal: false,
  showScanner: false,
  selectedQuestion: "",
  showSuggOptions: false,
  barcodeData: null,
  priceFilter: [25, 36000],
  suggestedQuestionData: null,
  productSpecficAns: "",
  configData: null,
};

const chatWindow = (state = initialState, action) => {
  switch (action.type) {
    case actionNames.SET_USER_INPUT:
      return {
        ...state,
        userInput: action.userInput,
      };
    case actionNames.SET_USER_MESSAGE:
      return {
        ...state,
        userMessage: action.userMessage,
      };
    case actionNames.SET_IS_LISTENING:
      return {
        ...state,
        listening: action.listening,
      };
    case actionNames.SET_BOT_MESSAGE:
      return {
        ...state,
        botMessage: action.botMessage,
      };
    case actionNames.SET_SHOW_HIDE_INPUT:
      return {
        ...state,
        showInput: action.showInput,
      };
    case actionNames.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actionNames.SET_SHOW_LOGOUT_MODAL:
      return {
        ...state,
        showLogOutModal: action.showLogOutModal,
      };
    case actionNames.SET_SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: action.showLoginModal,
      };
    case actionNames.SET_SELECTED_GENDER:
      return {
        ...state,
        selectedGender: action.selectedGender,
      };
    case actionNames.SET_SHOW_SCANNER:
      return {
        ...state,
        showScanner: action.showScanner,
      };
    case actionNames.SET_SELECTED_QUESTION:
      return {
        ...state,
        selectedQuestion: action.selectedQuestion,
      };
    case actionNames.SET_SHOW_SUGG_OPTIONS:
      return {
        ...state,
        showSuggOptions: action.showSuggOptions,
      };
    case actionNames.SET_BARCODE_DATA:
      return {
        ...state,
        barcodeData: action.barcodeData,
      };
    case actionNames.SET_PRICE_FILTER:
      return {
        ...state,
        priceFilter: action.priceFilter,
      };
    case actionNames.SET_SUGGESTED_QUESTION:
      return {
        ...state,
        suggestedQuestionData: action.suggestedQuestionData,
      };
    case actionNames.SET_PRODUCT_SPECIFIC_ANS:
      return {
        ...state,
        productSpecficAns: action.productSpecficAns,
      };
    case actionNames.SET_CONFIG_DATA:
      return {
        ...state,
        configData: action.configData,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chatWindow;
