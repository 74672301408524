import { baseUrl } from "./constents";

const urlParams = new URLSearchParams(window.location.search);
const temp = urlParams.get("store_uuid");
export const store_uuid = temp ? temp : "65704a7cbea15f27650ff6e2";

export const getAiBotResponse = async (body) => {
  const url = `${baseUrl}/store/${store_uuid}/chat`;
  const options = {
    method: "POST",
    headers: {
      Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options);
};

export const fetchMoreRecommandedProducts = async (
  category,
  productCode,
  offset,
  limit
) => {
  const url = `${baseUrl}/store/${store_uuid}/product_window/recommendations?type=${category}&product_code=${productCode}offset=${offset}&limit=${limit}&store_uuid=${store_uuid}`;
  try {
    const response = fetch(url);
    if (response.ok) {
      const data = (await response).json();
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

// export const getCurrentCategoryProducts = async (params) => {
//   const stylebot_query = localStorage.getItem("stylebot_query");
//   const stylebot_facets = localStorage.getItem("stylebot_facets");

//   const encodedFacets = encodeURIComponent(stylebot_facets);
//   const url = `${baseUrl}/search?query=${stylebot_query}&category=${params.category_id}&offset=0&limit=10&store_uuid=${store_uuid}&filters=${encodedFacets}`;

//   try {
//     const response = await fetch(url);
//     if (response.ok) {
//       const data = await response.json();
//       console.log(data);
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getRecommendedProductsData = async (
  type,
  productCode,
  store_uuid
) => {
  const url = `${baseUrl}/store/${store_uuid}/product_window/recommendations?type=${type}&product_code=${productCode}&offset=0&limit=10`;
  return fetch(url);
};

export const fetchMoreSearchProducts = async (queryParams) => {
  const url = `${baseUrl}/search?query=${
    queryParams.query
  }&filters=${JSON.stringify(queryParams.filters)}&category=${
    queryParams.category
  }&offset=${queryParams.offset + queryParams.limit}&limit=${
    queryParams.limit
  }&store_uuid=${store_uuid}`;
  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getSuggestions = async (messageId) => {
  const url =
    baseUrl +
    `/store/${store_uuid}/followup/question?message_id=` +
    messageId +
    "&keyword=true";
  return fetch(url);
};

export const getProductDetails = async (productId) => {
  const url = baseUrl + `/store/${store_uuid}/product/${productId}/info`;
  return fetch(url);
};
