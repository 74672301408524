import React, { useEffect, useState } from "react";
import closeIcon from "../../images/close.svg";
import "./style.scss";

const Modal = (props) => {
  const [menuHeight, setMenuHeight] = useState("1px");

  useEffect(() => {
    if (menuHeight === "0px") {
      setTimeout(() => {
        props.onClose();
      }, 500);
    }
  }, [menuHeight]);

  useEffect(() => {
    setMenuHeight("100%");
  }, []);

  const modalClasses = props.className
    ? `${props.className} modal-container`
    : "modal-container";

  const handleOnCloseModal = () => {
    setMenuHeight("0px");
  };

  return (
    <div
      className={`${modalClasses} ${props.slideUp && "slide-up"} `}
      id="modal-container"
      style={{
        height: `${menuHeight}`,
        maxHeight: props.maxHeight ? props.maxHeight : "94vh",
      }}
    >
      <div className="modal-nav">
        <div>{props.leftHeader && <div>{props.leftHeader}</div>}</div>
        <div className="close-btn" onClick={handleOnCloseModal}>
          <img src={closeIcon} alt="close-icon" />
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default Modal;
