import React from "react";

import backBtn from "../../images/back-icon.svg";
import blackStars from "../../images/stars-icon-black.svg";
import resetIcon from "../../images/reset-icon.svg";

import { useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import "./style.scss";
import { store_uuid } from "../../utils/utils";

const NavBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const user = localStorage.getItem("stylebot_user_id");

  // const handleOnClickLogin = () => {
  //   dispatch({ type: "SET_SHOW_LOGIN_MODAL", showLoginModal: true });
  // };

  const handleOnClickBack = () => {
    if (props.isLoadingRecom) {
      return;
    }

    if (props.showScanner) {
      dispatch({ type: "SET_SHOW_SCANNER", showScanner: false });
    }

    dispatch({
      type: "SET_SUGGESTED_QUESTION",
      suggestdQuestion: null,
    });

    if (window.location.pathname.includes("recommandation")) {
      navigate(
        `/product/${props.selectedProduct.product_code}?store_uuid=${store_uuid}`
      );
    } else if (
      window.location.pathname.includes("category") &&
      props.complementaryProducts &&
      props.complementaryProducts.length
    ) {
      navigate(
        `/product/${props.selectedProduct.product_code}?store_uuid=${store_uuid}`
      );
    } else if (window.location.pathname.includes("product")) {
      dispatch({
        type: "SET_COMPLEMENTARY_PRODUCTS",
        complementaryProducts: [],
      });
      dispatch({
        type: "SET_SELECTED_PRODUCT",
        selectedProduct: null,
      });
      navigate(
        `/category/${props.selectedInitCategory}?store_uuid=${store_uuid}`
      );
    } else {
      navigate(`/?store_uuid=${store_uuid}`);
      if (props.selectedInitCategory) {
        dispatch({
          type: "SET_INIT_SELECTED_CATEGORY",
          selectedInitCategory: "",
        });
      }
    }
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
  };

  const handleOnClickReset = () => {
    if (props.isLoading) {
      return;
    } else {
      window.location.href = "/";
    }
  };

  const handleOnClickHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="nav-container">
      <div>
        {window.location.pathname !== "/" && (
          <div onClick={handleOnClickBack}>
            <img src={backBtn} alt="bck-btn" />
          </div>
        )}
      </div>
      <div className="app-logo-container" onClick={handleOnClickHome}>
        <img
          src={
            props.configData && props.configData.logo !== "square_shape"
              ? props.configData.logo
              : blackStars
          }
          alt="app-icon"
          className="app-logo"
        />
        <div>
          {props.configData && props.configData.assistant_name
            ? props.configData.assistant_name
            : "Style Assist"}
        </div>
      </div>
      <div>
        {(window.location.pathname !== "/" ||
          (props.productWindow && props.productWindow.length > 0)) && (
          <div
            onClick={() => {
              if (!props.isLoading) {
                handleOnClickReset();
              }
            }}
          >
            <img src={resetIcon} alt="reset-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showLoginModal: state.chatWindow.showLoginModal,
  isLoading: state.chatWindow.isLoading,
  selectedGender: state.chatWindow.selectedGender,
  botMessage: state.chatWindow.botMessage,
  selectedProducts: state.productWindow.selectedProducts,
  selectedProduct: state.productWindow.selectedProduct,
  selectedCategory: state.productWindow.selectedCategory,
  productWindow: state.productWindow.productWindow,
  complementaryProducts: state.productWindow.complementaryProducts,
  selectedInitCategory: state.productWindow.selectedInitCategory,
  showScanner: state.chatWindow.showScanner,
  isLoadingRecom: state.productWindow.isLoadingRecom,
  configData: state.chatWindow.configData,
});

export default connect(mapStateToProps)(NavBar);
