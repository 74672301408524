import React, { useEffect, useRef, useState } from "react";
import micBigIcon from "../../images/mic-icon.svg";
import keypadIcon from "../../images/key-pad-icon.svg";
import micIcon from "../../images/mic-small-icon.svg";
import arrowIcon from "../../images/arrow-icon.svg";
import cameraIcon from "../../images/camera-icon.svg";
import arrowRight from "../../images/arrow-right.svg";

import {
  store_uuid,
  getSuggestions,
  getAiBotResponse,
} from "../../utils/utils";

import { connect, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import "./style.scss";

let recognition = null;
let transcript = "";

const Footer = (props) => {
  const inputRef = useRef("");
  const dispatch = useDispatch();
  const [isUserSpeaking, setUserSpeaking] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserSpeaking && props.userInput && props.userInput.length) {
      getBotResponse();
    }
  }, [isUserSpeaking]);

  const handleOnClickMic = () => {
    if (!isUserSpeaking) {
      if (recognition) {
        recognition.stop();
        recognition = null;
      } else if ("webkitSpeechRecognition" in window) {
        recognition =
          new window.webkitSpeechRecognition() ||
          new window.SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = "en-US";
        recognition.start();

        let finalTranscripts = "";
        let timeoutId;

        const resetTimeout = () => {
          clearTimeout(timeoutId);
          if (!isUserSpeaking && recognition) {
            timeoutId = setTimeout(() => {
              if (!props.isLoading) {
                recognition?.stop();
              }
            }, 1500);
          }
        };

        recognition.onstart = () => {
          setUserSpeaking(true);
        };

        recognition.onend = () => {
          setUserSpeaking(false);
          resetTimeout();
        };

        recognition.onresult = function (event) {
          let interimTranscripts = "";
          for (let i = event.resultIndex; i < event.results.length; i++) {
            transcript = event.results[i][0].transcript;
            transcript.replace("\n", "<br>");
            if (event.results[i].isFinal) {
              finalTranscripts += transcript;
              setUserSpeaking(false);
              resetTimeout();
            } else {
              interimTranscripts += transcript;
              setUserSpeaking(true);
            }
          }
          handleTranscript(finalTranscripts, interimTranscripts);
        };
      } else {
        console.log("Speech recognition not supported.");
      }
    } else {
      setUserSpeaking(false);
    }
  };

  const handleTranscript = (finalTranscripts, interimTranscripts) => {
    if (transcript.length) {
      dispatch({
        type: "SET_USER_INPUT",
        userInput: finalTranscripts + interimTranscripts,
      });
    }
  };

  const getSuggestionData = async (messageId) => {
    try {
      const response = await getSuggestions(messageId);
      if (response.ok) {
        const suggestionsObject = await response.json();
        dispatch({
          type: "SET_SUGGESTED_QUESTION",
          suggestedQuestionData: suggestionsObject,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBotResponse = async () => {
    let productId;
    const isProductWindow = window.location.pathname.includes("product");
    if (isProductWindow) {
      productId = window.location.pathname.split("/")[2];
    } else {
      dispatch({
        type: "SET_INIT_SELECTED_CATEGORY",
        selectedInitCategory: "",
      });
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        selectedProducts: [],
      });
      dispatch({
        type: "SET_PRODUCT_WINDOW",
        productWindow: [],
      });
      dispatch({
        type: "SET_BOT_MESSAGE",
        botMessage: "",
      });
      navigate(`/?${store_uuid}`);
    }

    dispatch({ type: "SET_IS_LOADING", isLoading: true });
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
    dispatch({
      type: "SET_COMPLEMENTARY_PRODUCTS",
      complementaryProducts: [],
    });

    dispatch({
      type: "SET_PRODUCT_SPECIFIC_ANS",
      productSpecficAns: "",
    });

    dispatch({
      type: "SET_SUGGESTED_QUESTION",
      suggestdQuestion: null,
    });

    const messageId = String(Date.now());
    const body = {
      message: props.userInput,
      user_id: localStorage.getItem("stylebot_user_id"),
      session_id: localStorage.getItem("stylebot_session_id"),
      message_id: messageId,
      price_range: [props.priceFilter[0], props.priceFilter[1]],
      openai_model: "gpt-3.5-turbo",
      openai_temperature: "0.33",
    };

    if (productId) {
      body["product_id"] = productId;
    }

    dispatch({
      type: "SET_USER_MESSAGE",
      userMessage: props.userInput,
    });
    dispatch({ type: "SET_USER_INPUT", userInput: "" });
    try {
      const response = await getAiBotResponse(body);
      if (response.ok) {
        const reader = response.body.getReader();
        let receivedData = "";
        while (true) {
          const { done, value } = await reader.read();
          const chunk = new TextDecoder().decode(value);

          receivedData += chunk;
          if (done) {
            console.log("done");
            const textEndIdx = receivedData.indexOf("[TXT_END]");
            if (!isProductWindow) {
              const productEndIdx = receivedData.lastIndexOf("[PRODUCT_END]");
              const additionalData = JSON.parse(
                receivedData.substring(textEndIdx + 9, productEndIdx)
              );
              const tempProductwindow = additionalData?.product_window;
              dispatch({
                type: "SET_PRODUCT_WINDOW",
                productWindow: tempProductwindow,
              });
            }
            dispatch({ type: "SET_IS_LOADING", isLoading: false });

            break;
          } else {
            const textEnd = chunk.split(" ").includes("[TXT_END]");
            const productEnd = chunk.split(" ").includes("[PRODUCT_END]");
            if (textEnd) {
              getSuggestionData(messageId);
              const lastIndexOfTxt = receivedData.lastIndexOf("[TXT_END]");
              const data = receivedData.substring(0, lastIndexOfTxt);
              if (window.location.pathname.includes("product")) {
                dispatch({
                  type: "SET_PRODUCT_SPECIFIC_ANS",
                  productSpecficAns: data,
                });
              } else {
                dispatch({ type: "SET_BOT_MESSAGE", botMessage: data });
              }
            } else if (
              !receivedData.includes("[TXT_END]") &&
              !productEnd &&
              !textEnd
            ) {
              if (window.location.pathname.includes("product")) {
                dispatch({
                  type: "SET_PRODUCT_SPECIFIC_ANS",
                  productSpecficAns: receivedData,
                });
              } else {
                dispatch({ type: "SET_BOT_MESSAGE", botMessage: receivedData });
              }
            }
          }
        }
      } else {
        dispatch({ type: "SET_IS_LOADING", isLoading: false });
        console.log("Somthing went wrong");
      }
    } catch (err) {
      dispatch({ type: "SET_IS_LOADING", isLoading: false });
      console.log(err);
    }
  };

  const handleOnClickCamera = () => {
    dispatch({ type: "SET_SHOW_SCANNER", showScanner: true });
  };

  const handleOnChangeUserInput = (evt) => {
    dispatch({ type: "SET_USER_INPUT", userInput: evt.target.textContent });
  };

  const handleOnClickKeyboard = () => {
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: !props.showInput,
    });
  };

  const handleOnKeyDown = (evt) => {
    if (props.isLoading) {
      return;
    } else {
      if (evt.key === "Enter" && !evt.shiftKey) {
        evt.preventDefault();
        const userInput = inputRef.current.textContent.trim();

        if (userInput.length > 0) {
          inputRef.current.textContent = "";
          dispatch({ type: "SET_USER_INPUT", userInput });
          getBotResponse();
        }
      }
    }
  };

  const handleOnChangeSuggestedQuestionData = (e) => {
    dispatch({
      type: "",
      suggestedQuestionData: {
        ...props.suggestedQuestionData,
        followup_question: e.target.value,
      },
    });
  };

  const handleOnShowSuggOption = (e) => {
    e.stopPropagation();
    if (props.isLoadingRecom) {
      return;
    }
    dispatch({ type: "SET_SHOW_SUGG_OPTIONS", showSuggOptions: true });
    dispatch({
      type: "SET_USER_MESSAGE",
      userMessage: "",
    });
  };

  return (
    <div className="footer-container">
      {props.suggestedQuestionData &&
        props.suggestedQuestionData.probable_answers &&
        props.suggestedQuestionData.probable_answers.length > 0 && (
          <div className="sugg-data-container">
            <div>
              <input
                value={props.suggestedQuestionData.question_keyword}
                onChange={handleOnChangeSuggestedQuestionData}
              />
            </div>
            <div
              className="sugg-option-container"
              onClick={handleOnShowSuggOption}
            >
              {props.suggestedQuestionData.question_keyword}
              <img src={arrowRight} alt="arrowRight" />
            </div>
          </div>
        )}
      {isUserSpeaking ? (
        <div>
          <div className="input-container">
            <img src={micIcon} alt="mic-icon" onClick={handleOnClickMic} />
            <div>Listening...</div>
          </div>
        </div>
      ) : props.showInput ? (
        <div className="input-container">
          <img
            src={micIcon}
            alt="mic-icon"
            onClick={() => {
              if (!props.isLoading) {
                handleOnClickMic();
              }
            }}
          />
          <div
            id="user-input"
            contentEditable={true}
            suppressContentEditableWarning={true}
            className="streamoid-user-input"
            placeholder="Type here..."
            onInput={handleOnChangeUserInput}
            onKeyDown={handleOnKeyDown}
            ref={inputRef}
          ></div>
          <img
            onClick={() => {
              if (!props.isLoading) {
                getBotResponse();
              }
            }}
            src={arrowIcon}
            alt="arrow-icon"
          />
        </div>
      ) : (
        <div className="mic-keypad">
          <div style={{ width: "54px" }} onClick={handleOnClickCamera}>
            <img src={cameraIcon} alt="cameraIcon" />
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              style={{ display: "none" }}
            />
          </div>
          <div className="mic-container">
            <img onClick={handleOnClickMic} src={micBigIcon} alt="mic-icon" />
          </div>
          <div className="keypad-container">
            <img
              onClick={handleOnClickKeyboard}
              src={keypadIcon}
              alt="key-pad"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  productWindow: state.productWindow.productWindow,
  selectedCategory: state.productWindow.selectedCategory,
  selectedProducts: state.productWindow.selectedProducts,
  selectedProduct: state.productWindow.selectedProduct,
  recommandedProducts: state.productWindow.recommandedProducts,
  userInput: state.chatWindow.userInput,
  suggestdQuestion: state.chatWindow.suggestdQuestion,
  showInput: state.chatWindow.showInput,
  botMessage: state.chatWindow.botMessage,
  isLoading: state.chatWindow.isLoading,
  priceFilter: state.chatWindow.priceFilter,
  suggestedQuestionData: state.chatWindow.suggestedQuestionData,
  isLoadingRecom: state.productWindow.isLoadingRecom,
});

export default connect(mapStateToProps)(Footer);
