import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BrowserMultiFormatReader } from "@zxing/library";
import { baseUrl } from "../../utils/constents";
import { store_uuid } from "../../utils/utils";
import attachIcon from "../../images/attach-icon.svg";

import "./style.scss";

const CodeScanner = () => {
  const [imgurl, setImgUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoRef = useRef();

  useEffect(() => {
    if (!imgurl) {
      const reader = new BrowserMultiFormatReader();
      if (!document.getElementById("video")) {
        return;
      }
      let selectedDeviceId;
      reader.listVideoInputDevices().then((videoInputDevices) => {
        selectedDeviceId = videoInputDevices[0].deviceId;
        console.log("selectedDeviceId: ", selectedDeviceId);
      });

      reader.decodeFromVideoDevice(selectedDeviceId, "video", (result, err) => {
        if (result) {
          console.log(result);
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
          navigateToCurrPage(result);
        }
      });
    }
  }, [imgurl]);

  const navigateToCurrPage = (result) => {
    dispatch({ type: "SET_SHOW_SCANNER", showScanner: false });
    navigate(`/product/${result.text}`);
  };

  const handleOncapture = () => {
    var canvas = document.getElementById("canvas");
    var video = document.getElementById("video");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext("2d")
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageDataURL = canvas.toDataURL("image/jpeg");
    setImgUrl(imageDataURL);
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleOnClickUse = async () => {
    const file = dataURLtoFile(imgurl, "image.png");
    console.log(file);
    const formData = new FormData();
    formData.append("image", file);
    formData.append("store_uuid", store_uuid);

    const options = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    const url = `${baseUrl}/visual-search`;

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnClickTryAgain = () => {
    setImgUrl(null);
  };

  const handleOnClickAttach = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let imgUrl;
    reader.onloadend = () => {
      imgUrl = reader.result;
      setImgUrl(imgUrl);
      e.target.files = null;
      const stream = videoRef.current.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  };

  return (
    <>
      {imgurl && imgurl.length > 0 ? (
        <div className="image-main-container">
          <div className="capturred-header-container">
            <div className="captured-header">Image Captured</div>
            <div className="captured-subfooter">
              Would you like to proceed with this image or capture another?
            </div>
          </div>
          <div className="image-container">
            <img src={imgurl} className="captured-image"  alt="captured-img"/>
          </div>
          <div className="btns-container">
            <div className="use-img-btn" onClick={handleOnClickUse}>
              Use This Photo
            </div>
            <div className="try-again-btn" onClick={handleOnClickTryAgain}>
              Try Again
            </div>
          </div>
        </div>
      ) : (
        <div className="video-container">
          <video ref={videoRef} id="video" className="video"></video>
          <div className="video-footer">
            <div className="attach-icon-container">
              <label htmlFor="streamoid-attach-input">
                <img id="streamoid-attach-icon" src={attachIcon} alt="attach" />
                <input
                  id="streamoid-attach-input"
                  type="file"
                  style={{ display: "none" }}
                  name="image"
                  accept="image/*"
                  onChange={handleOnClickAttach}
                />
              </label>
            </div>
            <div className="capture-btn" onClick={handleOncapture}>
              <div className="capture-btn-inner"></div>
            </div>
            <div></div>
          </div>
          <canvas
            id="canvas"
            style={{ overflow: "auto", display: "none" }}
          ></canvas>
        </div>
      )}
    </>
  );
};

export default CodeScanner;
