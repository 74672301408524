import React, { useState, useEffect } from "react";
import backBtn from "../../images/back-icon.svg";
import needleImg from "../../images/needle.svg";

import CardStack from "../../components/CardStack";
import Footer from "../../components/Footer";
import ProductsList from "../../components/ProductsList";
import LogoutModal from "../../components/LogoutModal";
import CodeScanner from "../../components/CodeScanner";

import { fetchMoreRecommandedProducts, store_uuid } from "../../utils/utils";

import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./RecommandetionWindow.scss";

const RecommandetionWindow = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (!props.selectedProduct) {
      window.location.href = "/";
    }
  }, []);

  const handleOnClickBack = () => {
    setSelectedCategory("");
    if (selectedProducts && selectedProducts.length > 0) {
      setSelectedProducts([]);
    } else {
      navigate(
        `/product/${props.selectedProduct.product_code}?store_uuid=${store_uuid}`
      );
      dispatch({
        type: "SET_SHOW_HIDE_INPUT",
        showInput: false,
      });
    }
  };

  const handleOnClickCategory = (cat) => {
    const tempProducts = props.recommandedProducts.filter(
      (item) => item.title === cat
    );

    if (tempProducts) {
      setSelectedProducts(tempProducts[0].products);
      setSelectedCategory(cat);
    }
  };

  const handleOnScrollProductContainer = async (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      let currProduct = {};
      if (props.recommandationType !== "similar") {
        currProduct = props.recommandedProducts.filter((item) => {
          if (item.title === selectedCategory) {
            return item;
          }
        })[0];
      } else {
        currProduct = props.recommandedProducts[0];
      }
      const pageParams = currProduct;
      if (pageParams.offset && pageParams.offset < pageParams.num_found) {
        const response = await fetchMoreRecommandedProducts(
          props.recommandationType,
          currProduct.product_code,
          pageParams.offset,
          pageParams.limit
        );

        console.log(response);
        setSelectedProducts([
          ...response.product_window[0].products,
          ...selectedProducts,
        ]);
      }
    }
  };

  const handleOnClickProduct = (product) => {
    dispatch({ type: "SET_SELECTED_PRODUCT", selectedProduct: product });
    navigate(`/product/${product.product_code}?store_uuid=${store_uuid}`);
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });
  };


  return (
    <>
      {props.showScanner ? (
        <>
          <CodeScanner />
        </>
      ) : (
        <div className="recommand-contianer">
          <div className="recommand-title">
            {props.recommandationType === "matcher"
              ? "Pair it with"
              : props.recommandationType === "outfitter"
              ? "Outfits"
              : "Similar Products"}
          </div>
          <div className="prod-back-container">
            {selectedCategory && selectedCategory.length && (
              <div onClick={handleOnClickBack}>
                <img src={backBtn} alt="bck-btn" />
              </div>
            )}
            <img
              src={props.selectedProduct && props.selectedProduct.image_url[0]}
              alt="icon"
              onError={(e) => (e.target.src = needleImg)}
              className="product-thumbnail"
            />
            <div>
              <div className="product-name">
                {props.selectedProduct && props.selectedProduct.name}
              </div>
              <div>
                {props.selectedProduct && props.selectedProduct.price}
                {props.selectedProduct && props.selectedProduct.currency}
              </div>
            </div>
          </div>
          <>
            {props.recommandedProducts &&
            props.recommandedProducts[0] &&
            props.recommandedProducts[0].title === "similar" ? (
              <>
                <ProductsList
                  products={props.recommandedProducts[0].products}
                  onClickProduct={handleOnClickProduct}
                  handleOnScrollProductContainer={
                    handleOnScrollProductContainer
                  }
                />
              </>
            ) : selectedProducts && selectedProducts.length > 0 ? (
              <>
                <ProductsList
                  products={selectedProducts}
                  onClickProduct={handleOnClickProduct}
                  handleOnScrollProductContainer={
                    handleOnScrollProductContainer
                  }
                />
              </>
            ) : (
              <CardStack
                productWindow={props.recommandedProducts}
                handleOnClickCategory={(title) => handleOnClickCategory(title)}
              />
            )}
          </>
          <Footer />
          {props.showLogOutModal && <LogoutModal />}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedProduct: state.productWindow.selectedProduct,
  recommandedProducts: state.productWindow.recommandedProducts,
  recommandationType: state.productWindow.recommandationType,
  showLogOutModal: state.chatWindow.showLogOutModal,
});

export default connect(mapStateToProps)(RecommandetionWindow);
