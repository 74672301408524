import React from "react";

import { connect } from "react-redux";

import "./style.scss";

const WhiteButtonList = (props) => {
  return (
    <div className="btn-container" style={props.style}>
      {props.btnList.map((item, idx) => {
        return (
          <div
            className={
              item === props.activeItem ? "black-btn" : "white-btn-list"
            }
            key={idx}
            onClick={() => {
              if (!props.isLoading) {
                props.onClick(item);
              }
            }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.chatWindow.isLoading,
});

export default connect(mapStateToProps)(WhiteButtonList);
