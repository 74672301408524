import React, { useRef } from "react";

import needleImg from "../../images/needle.svg";
import rupeeImg from "../../images/Indian-Rupee-symbol.svg";

import Loader from "../Loader";

import { fetchMoreRecommandedProducts } from "../../utils/utils";

import { connect } from "react-redux";

import "./style.scss";

const ProductsList = (props) => {
  const productsContainerRef = useRef(null);
  return (
    <div
      className="products-list-container"
      ref={productsContainerRef}
      onScroll={props.handleOnScrollProductContainer}
    >
      {props.products && props.products.length > 0 ? (
        props.products.map((product, idx) => {
          return (
            <div
              key={idx}
              className="product-container"
              onClick={() => props.onClickProduct(product)}
            >
              <div className="product-img-container">
                <img
                  src={product.image_url && product.image_url[0]}
                  alt="product-img"
                  className="product-image card"
                  onError={(e) => (e.target.src = needleImg)}
                />
              </div>
              <div className="product-name">
                {product.name.substring(0, 30)}
              </div>
              <div className="product-price">
                {product.currency === "INR" ? (
                  <img
                    src={rupeeImg}
                    alt="rupee"
                    style={{ height: "8px", width: "8px" }}
                  />
                ) : (
                  product.currency
                )}
                {` ${product.price}`}
              </div>
            </div>
          );
        })
      ) : (
        <div>No products to show</div>
      )}
      {props.isLoading && (
        <div
          className="loader-component"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexWrap:"wrap",
          }}
        >
          {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Loader />
        </div> */}
          {[...Array(8)].map((_, idx) => {
            return <div key={idx} className="card"></div>;
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.productWindow.selectedCategory,
  selectedProduct: state.productWindow.selectedProduct,
  recommandedProducts: state.productWindow.recommandedProducts,
  isLoading: state.chatWindow.isLoading,
});

export default connect(mapStateToProps)(ProductsList);
