import React, { useEffect, useState } from "react";

import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { parallelLimit, reflectAll } from "async";
import {
  store_uuid,
  getRecommendedProductsData,
  getProductDetails,
  getSuggestions,
  getAiBotResponse,
} from "../../utils/utils";

import rupeeImg from "../../images/Indian-Rupee-symbol.svg";
import showAll from "../../images/show-all-arrow.svg";
import homeIcon from "../../images/home-icon.svg";

import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import LogoutModal from "../../components/LogoutModal";
import CodeScanner from "../../components/CodeScanner";
import Modal from "../../components/Modal";

import "./productsWindow.scss";

const ProductsWindow = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    if (!props.selectedProduct) {
      navigate("/");
    } else if (props.selectedProduct) {
      dispatch({
        type: "SET_COMPLEMENTARY_PRODUCTS",
        complementaryProducts: [],
      });
      dispatch({
        type: "SET_SIMILAR_PRODUCTS",
        similarProducts: [],
      });
      getRecommendition();
      getProductData();
    } else if (!props.showScanner) {
      getRecommendition();
      getProductData();
    }
  }, [props.selectedProduct, props.showScanner]);

  const getProductData = async () => {
    try {
      const response = await getProductDetails(
        props.selectedProduct.product_code
      );
      if (response.ok) {
        const responseData = await response.json();
        setProductData(responseData);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const handleOnClickSaveAndProceed = () => {
    dispatch({ type: "SET_SHOW_SUGG_OPTIONS", showSuggOptions: false });
    getBotResponse(false);
  };

  const handleOnSelectFollowUp = (ans) => {
    if (props.userInput && props.userInput.includes(ans)) {
      const temp = props.userInput.split(",").filter((item) => item !== ans);
      dispatch({ type: "SET_USER_INPUT", userInput: temp.join(",") });
      dispatch({
        type: "SET_USER_MESSAGE",
        userMessage: temp.join(","),
      });
    } else {
      let temp = props.userInput ? props.userInput : false;
      if (temp) {
        dispatch({
          type: "SET_USER_INPUT",
          userInput: temp + "," + ans,
        });
      } else {
        dispatch({
          type: "SET_USER_INPUT",
          userInput: ans,
        });
      }
      temp = props.userMessage ? props.userMessage : false;
      if (temp) {
        dispatch({
          type: "SET_USER_MESSAGE",
          userMessage: temp + "," + ans,
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          userMessage: ans,
        });
      }
    }
  };

  const getRecommendition = async () => {
    dispatch({ type: "SET_ISLOADING_RECOM", isLoadingRecom: true });
    const funList = [
      async () => {
        try {
          return await getRecommendedProducts("similar");
        } catch (error) {
          console.log("Error in getRecommendedProducts('similar'):", error);
        }
      },
      async () => {
        try {
          return await getRecommendedProducts("matcher");
        } catch (error) {
          console.log("Error in getRecommendedProducts('matcher'):", error);
        }
      },
    ];

    parallelLimit(reflectAll(funList), 2, (err, results) => {
      dispatch({ type: "SET_ISLOADING_RECOM", isLoadingRecom: false });

      const success = [];
      const failed = [];

      results.forEach((result) => {
        if (result.error) {
          failed.push(result.error);
        } else {
          success.push(result.value);
        }
      });

      if (failed.length) {
        console.log("Errors in parallel execution:", failed);
      }
    });
  };

  const getSuggestionData = async (messageId) => {
    try {
      const response = await getSuggestions(messageId);
      if (response.ok) {
        const suggestionsObject = await response.json();
        dispatch({
          type: "SET_SUGGESTED_QUESTION",
          suggestedQuestionData: suggestionsObject,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBotResponse = async () => {
    let productId;
    const isProductWindow = window.location.pathname.includes("product");
    if (isProductWindow) {
      productId = window.location.pathname.split("/")[2];
    } else {
      dispatch({
        type: "SET_INIT_SELECTED_CATEGORY",
        selectedInitCategory: "",
      });
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        selectedProducts: [],
      });
      dispatch({
        type: "SET_PRODUCT_WINDOW",
        productWindow: [],
      });

      dispatch({
        type: "SET_BOT_MESSAGE",
        botMessage: "",
      });
      navigate(`?store_uuid=${store_uuid}`);
    }

    dispatch({ type: "SET_IS_LOADING", isLoading: true });
    dispatch({
      type: "SET_SHOW_HIDE_INPUT",
      showInput: false,
    });

    dispatch({
      type: "SET_SUGGESTED_QUESTION",
      suggestdQuestion: null,
    });

    dispatch({
      type: "SET_PRODUCT_SPECIFIC_ANS",
      productSpecficAns: "",
    });

    dispatch({
      type: "SET_COMPLEMENTARY_PRODUCTS",
      complementaryProducts: [],
    });

    const messageId = String(Date.now());
    const body = {
      message: props.userInput,
      user_id: localStorage.getItem("stylebot_user_id"),
      session_id: localStorage.getItem("stylebot_session_id"),
      message_id: messageId,
      price_range: [props.priceFilter[0], props.priceFilter[1]],
      openai_model: "gpt-3.5-turbo",
      openai_temperature: "0.33",
    };

    if (productId) {
      body["product_id"] = productId;
    }

    dispatch({
      type: "SET_USER_MESSAGE",
      userMessage: props.userInput,
    });
    dispatch({ type: "SET_USER_INPUT", userInput: "" });
    try {
      const response = await getAiBotResponse(body);
      if (response.ok) {
        const reader = response.body.getReader();
        let receivedData = "";
        while (true) {
          const { done, value } = await reader.read();
          const chunk = new TextDecoder().decode(value);

          receivedData += chunk;
          if (done) {
            console.log("done");
            const textEndIdx = receivedData.indexOf("[TXT_END]");
            if (!isProductWindow) {
              const productEndIdx = receivedData.lastIndexOf("[PRODUCT_END]");
              const additionalData = JSON.parse(
                receivedData.substring(textEndIdx + 9, productEndIdx)
              );
              const tempProductwindow = additionalData?.product_window;
              dispatch({
                type: "SET_PRODUCT_WINDOW",
                productWindow: tempProductwindow,
              });
            }
            dispatch({ type: "SET_IS_LOADING", isLoading: false });

            break;
          } else {
            const textEnd = chunk.split(" ").includes("[TXT_END]");
            const productEnd = chunk.split(" ").includes("[PRODUCT_END]");
            if (textEnd) {
              getSuggestionData(messageId);
              const lastIndexOfTxt = receivedData.lastIndexOf("[TXT_END]");
              const data = receivedData.substring(0, lastIndexOfTxt);
              if (window.location.pathname.includes("product")) {
                dispatch({
                  type: "SET_PRODUCT_SPECIFIC_ANS",
                  productSpecficAns: data,
                });
              } else {
                dispatch({ type: "SET_BOT_MESSAGE", botMessage: data });
              }
            } else if (
              !receivedData.includes("[TXT_END]") &&
              !productEnd &&
              !textEnd
            ) {
              if (window.location.pathname.includes("product")) {
                dispatch({
                  type: "SET_PRODUCT_SPECIFIC_ANS",
                  productSpecficAns: receivedData,
                });
              } else {
                dispatch({ type: "SET_BOT_MESSAGE", botMessage: receivedData });
              }
            }
          }
        }
      } else {
        dispatch({ type: "SET_IS_LOADING", isLoading: false });
        console.log("Somthing went wrong");
      }
    } catch (err) {
      dispatch({ type: "SET_IS_LOADING", isLoading: false });
      console.log(err);
    }
  };

  const getRecommendedProducts = async (type) => {
    dispatch({ type: "SET_RECOMMANDATION_TYPE", recommandationType: type });
    const productCode = window.location.pathname.split("/")[2];
    if (!productCode) {
      return;
    }
    try {
      const response = await getRecommendedProductsData(
        type,
        productCode,
        store_uuid
      );
      if (response.ok) {
        const data = await response.json();
        if (data.product_window && data.product_window.length) {
          if (type === "similar") {
            dispatch({
              type: "SET_SIMILAR_PRODUCTS",
              similarProducts: data.product_window,
            });
          } else {
            dispatch({
              type: "SET_COMPLEMENTARY_PRODUCTS",
              complementaryProducts: data.product_window,
            });
          }
          dispatch({
            type: "SET_SHOW_HIDE_INPUT",
            showInput: false,
          });
        }
        return "success";
      }
    } catch (err) {
      console.log(err);
      return "fail";
    }
  };

  const handleOnClickProduct = (prod) => {
    dispatch({
      type: "SET_SELECTED_PRODUCT",
      selectedProduct: prod,
    });
  };

  const handleOnClickBuyNow = () => {
    setShowBuyModal(true);
  };

  const handleOnClickCategory = (category) => {
    if (props.isLoadingRecom || props.isLoading) {
      return;
    }

    let currCategory = props.productWindow.filter(
      (cat) => cat.title === category.title
    )[0];

    if (props.complementaryProducts && props.complementaryProducts.length > 0) {
      currCategory = props.complementaryProducts.filter(
        (cat) => cat.title === category.title
      )[0];

      dispatch({
        type: "SET_SELECTED_CATEGORY",
        selectedCategory: category.title,
      });
    } else {
      dispatch({
        type: "SET_INIT_SELECTED_CATEGORY",
        selectedInitCategory: category.title,
      });
    }

    if (currCategory && currCategory.products) {
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        selectedProducts: currCategory.products,
      });

      navigate(`/category/${category.title}?store_uuid=${store_uuid}`);
      dispatch({
        type: "SET_SHOW_HIDE_INPUT",
        showInput: false,
      });
    }
  };

  const handleOnClickViewAll = (type) => {
    if (props.isLoadingRecom || props.isLoading) {
      return;
    }
    dispatch({
      type: "SET_RECOMMANDATION_TYPE",
      recommandationType: type,
    });

    if (type === "similar") {
      dispatch({
        type: "SET_RECOMMANDED_PRODUCTS",
        recommandedProducts: props.similarProducts,
      });
    } else {
      dispatch({
        type: "SET_RECOMMANDED_PRODUCTS",
        recommandedProducts: props.complementaryProducts,
      });
    }
    navigate(`/recommandation?store_uuid=${store_uuid}`);
  };

  return (
    <>
      {props.isLoading && (
        <div className="pdp-loader-container">
          <Loader />
        </div>
      )}
      {props.showScanner ? (
        <>
          <CodeScanner />
        </>
      ) : (
        <div className="product-window-contianer">
          <div className="rec-section">
            <div className="prod-back-container">
              <div>
                <div className="product-name">
                  {props.selectedProduct && props.selectedProduct.name}
                </div>
                <div>
                  {props.selectedProduct &&
                  props.selectedProduct.currency &&
                  props.selectedProduct.currency === "INR" ? (
                    <img
                      src={rupeeImg}
                      alt="rupee"
                      style={{ height: "12px", width: "12px" }}
                    />
                  ) : (
                    props.selectedProduct && props.selectedProduct.currency
                  )}
                  {props.selectedProduct && props.selectedProduct.price}
                </div>
              </div>
            </div>

            <div className="selected-prod-img-top-container">
              <div className="product-img-container">
                <img
                  src={
                    props.selectedProduct && props.selectedProduct.image_url[0]
                  }
                  className="product-image"
                  alt="product_img"
                />
              </div>
              <div className="buy-now" onClick={handleOnClickBuyNow}>
                Buy Now
              </div>
              <div>
                {productData &&
                productData.description &&
                productData.description.length
                  ? productData.description
                  : ""}
              </div>
            </div>

            <div className="similar-complelentary">
              <div className="similar-complelentary-sub">
                <div className="sub-headers">
                  <div>Similar Products</div>
                  <div
                    className="arrow-container"
                    onClick={() => handleOnClickViewAll("similar")}
                  >
                    <img src={showAll} alt="show-all" />
                  </div>
                </div>
                {props.isLoadingRecom ? (
                  <Loader />
                ) : (
                  <div className="products-horiz-container">
                    <div
                      style={{ display: "flex", overflow: "auto", gap: "8px" }}
                    >
                      {props.similarProducts &&
                        props.similarProducts.length > 0 &&
                        props.similarProducts[0].products.map((prod, idx) => {
                          return (
                            <div
                              className="similar-prod-container"
                              onClick={() => handleOnClickProduct(prod)}
                              key={idx}
                            >
                              <div className="prod-img-container">
                                <img
                                  src={prod.image_url ? prod.image_url[0] : ""}
                                  className="prod-img"
                                  alt="product_img"
                                />
                              </div>
                              <div className="product-name">{prod.name}</div>
                              <div className="product-price">
                                {prod.price} {prod.currency}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              <div className="similar-complelentary-sub">
                <div className="sub-headers">
                  <div>Pair it with</div>
                  <div
                    className="arrow-container"
                    onClick={() => handleOnClickViewAll("matcher")}
                  >
                    <img src={showAll} alt="show-all" />
                  </div>
                </div>
                {props.isLoadingRecom ? (
                  <Loader />
                ) : (
                  <div className="products-horiz-container">
                    <div
                      style={{ display: "flex", overflow: "auto", gap: "8px" }}
                    >
                      {props.complementaryProducts &&
                        props.complementaryProducts.length > 0 &&
                        props.complementaryProducts.map((category, idx) => {
                          return (
                            <div
                              className="similar-prod-container"
                              onClick={() => handleOnClickCategory(category)}
                              key={idx}
                            >
                              <div className="prod-img-container">
                                <img
                                  src={
                                    category.products &&
                                    category.products.length &&
                                    category.products[0].image_url
                                      ? category.products[0].image_url
                                      : ""
                                  }
                                  className="prod-img"
                                  alt="product_img"
                                />
                              </div>
                              <div className="product-name">
                                {category.title}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <Footer />
            {props.showLogOutModal && <LogoutModal />}
            {showBuyModal && (
              <Modal
                className="modal-visible"
                maxHeight="75vh"
                slideUp={showBuyModal}
                onClose={() => setShowBuyModal(false)}
              >
                <div className="buy-now-container">
                  <div className="home-icon-container">
                    <img src={homeIcon} alt="homeIcon" />
                  </div>
                  <div className="take-home-header">
                    Ready to take this home?
                  </div>
                  <div className="take-home-desc">
                    Our friendly sales executives are happy to assist you! Head
                    over to the nearest one and they'll help you locate the
                    product and complete your purchase.
                  </div>
                  <div
                    className="go-back-btn"
                    onClick={() => setShowBuyModal(false)}
                  >
                    Go Back
                  </div>
                </div>
              </Modal>
            )}
            {props.productSpecficAns && props.productSpecficAns.length && (
              <Modal
                className="modal-visible"
                maxHeight="65vh"
                slideUp={
                  props.productSpecficAns && props.productSpecficAns.length
                }
                onClose={() =>
                  dispatch({
                    type: "SET_PRODUCT_SPECIFIC_ANS",
                    botMessage: "",
                  })
                }
              >
                <div className="prod-spec-ans">
                  <div className="prod-spec-qus">{props.userMessage}</div>
                  <div className="ans">{props.productSpecficAns}</div>
                </div>
              </Modal>
            )}
            {props.showSuggOptions && (
              <Modal
                className="modal-visible"
                slideUp={props.showSuggOptions}
                onClose={() =>
                  dispatch({
                    type: "SET_SHOW_SUGG_OPTIONS",
                    showSuggOptions: false,
                  })
                }
              >
                <div className="sugg-modal-container">
                  <div className="modal-main-header">
                    {props.suggestedQuestionData &&
                      props.suggestedQuestionData.followup_question}
                  </div>
                  <div className="sugg-options-container">
                    {props.suggestedQuestionData &&
                      props.suggestedQuestionData.followup_options &&
                      props.suggestedQuestionData.followup_options.length &&
                      props.suggestedQuestionData.followup_options.map(
                        (item, idx) => {
                          return (
                            <div className="sugg-option" key={idx}>
                              <input
                                type="checkbox"
                                onChange={() => handleOnSelectFollowUp(item)}
                              />
                              <div>{item}</div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div
                    className="save-proceed"
                    onClick={handleOnClickSaveAndProceed}
                  >
                    Save and Proceed
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productWindow: state.productWindow.productWindow,
    currentProducts: state.productWindow.currentProducts,
    selectedProducts: state.productWindow.selectedProducts,
    selectedCategory: state.productWindow.selectedCategory,
    selectedProduct: state.productWindow.selectedProduct,
    showLogOutModal: state.chatWindow.showLogOutModal,
    userMessage: state.chatWindow.userMessage,
    userInput: state.chatWindow.userInput,
    similarProducts: state.productWindow.similarProducts,
    complementaryProducts: state.productWindow.complementaryProducts,
    isLoading: state.chatWindow.isLoading,
    showScanner: state.chatWindow.showScanner,
    barcodeData: state.chatWindow.barcodeData,
    productSpecficAns: state.chatWindow.productSpecficAns,
    isLoadingRecom: state.productWindow.isLoadingRecom,
    suggestedQuestionData: state.chatWindow.suggestedQuestionData,
    showSuggOptions: state.chatWindow.showSuggOptions,
    priceFilter: state.chatWindow.priceFilter,
  };
};

export default connect(mapStateToProps)(ProductsWindow);
